import React from 'react';

import { Quiz } from '../types';
import ListButton from './ListButton';
import DeleteQuizButtonComponent from './DeleteQuizButtonComponent';

const QuizListComponent = ({
  quizList,
  setSelectedQuiz,
  deleteButton,
}: {
  quizList: Quiz[];
  setSelectedQuiz: React.Dispatch<React.SetStateAction<Quiz | undefined>> | ((quiz: Quiz) => void) | Quiz;
  deleteButton?: boolean;
}) => {
    let enableDelete = false;
  const handleQuizSelection = (quiz: Quiz) => {
    if (typeof setSelectedQuiz === 'function') {
      setSelectedQuiz(quiz);
      enableDelete = true;
    } else {
    }
  };

  return (
    <div className='flex flex-col flex-grow justify-center bg-slate-800 md:px-5 md:py-5 mx-2 md:mx-10 shadow-black shadow-xl rounded-md'>
      <div className='flex flex-col px-2 md:px-20 md:pt-10'>
        {quizList.map((quiz) => (
          <div className='flex items-center justify-center'>
            <ListButton quiz={quiz} handleButtonPress={handleQuizSelection}/>
            {deleteButton && <DeleteQuizButtonComponent quiz={quiz} />}
          </div>
          // <button
          //   onClick={() => handleQuizSelection(quiz)}
          //   key={quiz.id}
          //   className={`text-green-400 p-2 md:p-5 rounded-md shadow-md shadow-black my-2 md:text-lg font-bold border border-slate-400 bg-slate-900 hover:border-slate-500 hover:bg-slate-950`}
          // >
          //   <h2 className='text-3xl line-clamp-1 overflow-hidden'>{quiz.title}</h2>
          // </button>
        ))}
      </div>
    </div>
  );
};

export default QuizListComponent;