import React from 'react';
import FloatingButton from './AnimatedButton';


const HomeButton = ({ ...props }) => {

    const { label, onClick } = props;

  return (
    <FloatingButton {...props} className='text-green-400 p-2 rounded-lg w-full h-20 font-bold font-SoulGaze'>
        <h1>{label}</h1>
    </FloatingButton>
  );
};

export default HomeButton;