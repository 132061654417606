import React from 'react';

import { Quiz } from '../types';

interface ListButtonProps {
    quiz: Quiz;
    handleButtonPress: (args: Quiz) => void;
}

export default (props: ListButtonProps) => {
    return (
        <button
            onClick={() => props.handleButtonPress(props.quiz)}
            key={props.quiz.id}
            className={`flex-grow text-green-400 p-2 md:p-5 rounded-md shadow-md shadow-black my-2 md:text-lg font-bold border border-slate-400 bg-slate-900 hover:border-slate-500 hover:bg-slate-950`}
          >
            <h2 className='text-3xl line-clamp-1 overflow-hidden font-SoulGaze tracking-widest font-thin'>{props.quiz.title}</h2>
          </button>
    );
}