import React from 'react';
import { FaTrash, FaWindowClose } from 'react-icons/fa';
import { FiInfo } from "react-icons/fi";

import Modal from './Modal';
import { useAppDispatch } from '../store/store';
import { Quiz } from '../types';
import { deleteQuizByIdRequest } from '../endpoints';
import { removeQuizFromQuizList } from '../store/features/QuizListSlice';
import LoadingOverlay from './LoadingOverlay';

interface DeleteQuizButtonProps {
    quiz: Quiz;
}

export default (props: DeleteQuizButtonProps) => {

    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const { quiz } = props;
    const dispatch = useAppDispatch();
    const [ localLoading, setLocalLoading ] = React.useState<boolean>(false);

    const handleDeleteSelection = async () => {
        setLocalLoading(true);
        const deleteResponse = await deleteQuizByIdRequest(quiz.id);

        if(deleteResponse.quizResult.acknowledged){
            dispatch(removeQuizFromQuizList(quiz.id));
            setIsModalOpen(false);
        }else{
            alert('Quiz Not Deleted');
        }
        setLocalLoading(false);
    }
    
    return (
        <>
            <button onClick={() => setIsModalOpen(true)} className='text-red-600 p-2 md:p-5 rounded-md mx-2 md:text-lg font-bold hover:text-slate-200 hover:bg-red-600'>
                <FaTrash className='text-xl'/>
            </button>
            <Modal openState={isModalOpen}>
                    {!localLoading ? <div className='flex flex-col gap-5 bg-slate-800 text-slate-500 p-5 rounded-lg w-11/12 md:w-1/3'>
                        <div className='flex justify-end w-fill'>
                            <button onClick={() => setIsModalOpen(false)}>
                                <FaWindowClose className='text-2xl'/>
                            </button>
                        </div>
                        <h2 className='text-red-600 font-bold text-xl md:text-3xl text-center'>Delete Quiz</h2>
                        <h2 className='text-slate-400'>Are you sure you would like to delete quiz <span className='text-slate-200 font-semibold underline'>{quiz.title}</span></h2>
                        <p className='flex items-baseline md:items-center gap-2'><FiInfo className='text-slate-100'/>Deleting This Quiz will also delete all attempts</p>
                        <div className='flex gap-3 md:gap-10'>
                            <button onClick={() => setIsModalOpen(false)} className='flex items-center w-1/2 text-slate-300 p-2 md:p-5 rounded-md shadow-md shadow-black my-2 md:text-lg font-bold border border-slate-900 bg-slate-900 hover:border-slate-500 hover:bg-slate-950'>
                                {/* <RxCircleBackslash  className='text-2xl'/> */}
                                <p className='flex-grow text-xl'>Cancel</p>
                            </button>
                            <button onClick={() => handleDeleteSelection()} className='flex items-center w-1/2 text-slate-300 p-2 md:p-5 rounded-md shadow-md shadow-black my-2 md:text-lg font-bold border border-slate-900 bg-slate-900 hover:border-slate-500 hover:bg-slate-950'>
                                {/* <FaTrash className='text-1xl'/> */}
                                <p className='flex-grow text-red-600 text-xl'>Delete</p>
                            </button>
                        </div>
                    </div>
                    :
                    <LoadingOverlay />}
                </Modal>
        </>
    );
}

