import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Quiz, Question } from '../../types';
import { fetchQuizListExcludeAnswersRequest, fetchQuizListRequest } from '../../endpoints';
import useAppSelector from '../store';

interface QuizListState {
    quizList: Quiz[];
}

const initialState: QuizListState = {
    quizList: [{
        id: '',
        title: '',
        questions: []
    }]
}

export const fetchQuizListExcludeAnswers = createAsyncThunk(
    'quizList/fetchQuizExcludeAnswers',
    async () => {
        const quizList = await fetchQuizListExcludeAnswersRequest();
        return quizList;
    }
);

export const fetchQuizList = createAsyncThunk(
    'quizList/fetchQuizList',
    async ({userId, userIdToken}: {userId: string, userIdToken: string}) => {
        const quizList = await fetchQuizListRequest(userId, userIdToken);
        return quizList;
    }
);

export const QuizListSlice = createSlice({
    name: 'quizzes',
    initialState,
    reducers: {
        setQuizList: (state, action: PayloadAction<Quiz[]>) => {
            state.quizList = action.payload;
        },
        removeQuizFromQuizList: (state, action: PayloadAction<string>) => {
            state.quizList = state.quizList.filter(quiz => quiz.id !== action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchQuizListExcludeAnswers.fulfilled, (state, action: PayloadAction<Quiz[]>) => {
            state.quizList = action.payload;
        });
        builder.addCase(fetchQuizList.fulfilled, (state, action: PayloadAction<Quiz[]>) => {
            state.quizList = action.payload;
        });
    }
});

export default QuizListSlice.reducer;

export const { setQuizList, removeQuizFromQuizList } = QuizListSlice.actions;