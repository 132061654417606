import React from 'react';
import logo from '../assets/images/logo-nobg.png';

const LoadingOverlay = () => {



    return (
        <div className='fixed top-0 left-0 w-screen h-screen bg-slate-900 bg-opacity-90 flex items-center justify-center z-50'>
            <div className='flex flex-col items-center gap-4'>
                <div className='relative w-40 h-w-40 animate-rotate'>
                    <img src={logo} className='absolute bottom-0 p-3 z-50'/>
                    <div className='absolute bottom-0 bg-slate-300 rounded-full w-40 h-40 z-40'/>
                </div>
                <div className='bg-slate-800 p-5 rounded-lg'>
                    <h1 className='text-3xl text-green-400 animate-pulse font-Bowdown tracking-widest'>LOADING...</h1>
                </div>
            </div>
        </div>
    );
};

export default LoadingOverlay;