import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Quiz, Question, UserProfile } from '../types';
import { createQuestionServerRequest, createQuizServerRequest, updateQuizQuestionsServerRequest } from '../endpoints';

interface CreateQuizState {
    quiz: Quiz;
}

const initialState: CreateQuizState = {
    quiz: {
        id: '',
        title: '',
        questions: [],
        author: {} as UserProfile,
    }
}

export const createQuizRequest = createAsyncThunk(
    'quiz/createQuiz',
    async ({ title, author, userIdToken, questions = [] }: { title: string, author: UserProfile, userIdToken: string, questions?: Question[] }) => {
        if (title === '') {
            return initialState.quiz;
        }
        const newQuizDetails = await createQuizServerRequest(title, author, userIdToken, questions);
        return {
            ...newQuizDetails,
            id: newQuizDetails.insertedId,
            title: title,
            questions: questions,
            author: author,
        };
    }
);

export const addQuestionRequest = createAsyncThunk(
    'quiz/addQuestion',
    async ({id, question, userIdToken}: {id:string, question: Question, userIdToken: string}) => {
        const newQuestion = await createQuestionServerRequest(id, question, userIdToken);
        return question;
    }
);

export const updateQuestionsRequest = createAsyncThunk(
    'quiz/updateQuestion',
    async ({id, questions, userIdToken}: {id:string, questions: Question[], userIdToken: string}) => {
        const updatedQuestion = await updateQuizQuestionsServerRequest(id, questions, userIdToken);
        return questions;
    }
);

export const QuizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        createQuiz: (state, action: PayloadAction<Quiz>) => {
            state.quiz.id = action.payload.id;
            state.quiz.title = action.payload.title;
            state.quiz.questions = action.payload.questions;
            state.quiz.author = action.payload.author;
        },
        addQuestion: (state, action: PayloadAction<Question>) => {
            if(!state.quiz.questions) {
                state.quiz.questions = [];
            }else{
                state.quiz.questions.push(action.payload);
            }
        },
        removeQuestion: (state, action: PayloadAction<string>) => {
            state.quiz.questions = state.quiz.questions.filter(question => question.id !== action.payload);
        },
        updateQuestion: (state, action: PayloadAction<Question>) => {
            const index = state.quiz.questions.findIndex(question => question.id === action.payload.id);
            state.quiz.questions[index] = action.payload;
        },
        updateQuestions: (state, action: PayloadAction<Question[]>) => {
            state.quiz.questions = action.payload;
        },
        updateQuizTitle: (state, action: PayloadAction<string>) => {
            state.quiz.title = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createQuizRequest.fulfilled, (state, action: PayloadAction<Quiz>) => {
            state.quiz.id = action.payload.id;
            state.quiz.title = action.payload.title;
            state.quiz.questions = action.payload.questions;
            state.quiz.author = action.payload.author;
        });
        builder.addCase(addQuestionRequest.fulfilled, (state, action: PayloadAction<Question>) => {
            if(action.payload.id === '0'){
                state.quiz.questions = [action.payload];
            }else{
                state.quiz.questions.push(action.payload);
            }
        });
        builder.addCase(updateQuestionsRequest.fulfilled, (state, action: PayloadAction<Question[]>) => {
            state.quiz.questions = action.payload;
        });
        
    }
});

export default QuizSlice.reducer;

export const { createQuiz, addQuestion, removeQuestion, updateQuestion, updateQuizTitle } = QuizSlice.actions;