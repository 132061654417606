import BackButton from './BackButton';

interface PageHeaderBannerProps {
    title: string;
    span?: string;
}

export default (props: PageHeaderBannerProps) => {
    return(
        <>
            <BackButton additionalStyles={'px-3 md:hidden'}/>
            <div className='flex flex-col md:flex-row bg-slate-800 py-5 md:py-10 mx-2 md:mx-10 shadow-black shadow-xl rounded-md'>
                <BackButton additionalStyles={'px-3 hidden md:flex font-SoulGaze tracking-widest'}/>
                <h1 className='flex-grow text-green-400 font-bold text-2xl md:text-5xl text-center font-SoulGaze tracking-widest'>{props.title}{props.span && <span className='text-slate-300 font-SoulGazeItalic'>{props.span}</span>}</h1>
            </div>
        </>
    );
}