import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import he from 'he';

import { useAppSelector } from '../store/store';
import { QuizAttemptResult } from '../types';

const Results = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const routePath = location.pathname;
    const quizAttemptResults: QuizAttemptResult = useAppSelector((state) => state.quizAttempt.quizAttemptResult);
    const scoreColor = quizAttemptResults.score && quizAttemptResults.score < 70 || quizAttemptResults.score === 0 ? 'text-red-500' : quizAttemptResults.score && quizAttemptResults.score < 80 ? 'text-yellow-400' : 'text-green-500';

    const handleAnswerDisplay = (selectedAnswer: string, correctAnswer: string, option: string) => {
        const decodedOption = he.decode(option);
        if(option !== correctAnswer && option !== selectedAnswer) {
            return <div key={option} className={`text-slate-300 p-5 rounded-md shadow-md shadow-black my-2 text-lg font-bold border border-slate-900 bg-slate-900 font-SoulGaze tracking-widest`}>{decodedOption}</div>
        }else if(correctAnswer === selectedAnswer || option === correctAnswer) {
            return <div key={option} className={`text-slate-300 p-5 rounded-md shadow-md shadow-black my-2 text-lg font-bold border border-green-500 bg-slate-900 font-SoulGaze tracking-widest`}>{decodedOption}</div>
        }else if(option === selectedAnswer) {
            return <div key={option} className={`text-slate-300 p-5 rounded-md shadow-md shadow-black my-2 text-lg font-bold border border-yellow-500 bg-slate-900 font-SoulGaze tracking-widest`}>{decodedOption}</div>
        }
    }

    useEffect(() => {
        if(!quizAttemptResults.quiz.questions){
            navigate(-1);
        }
    }, [])

    return (
        <div className='flex flex-col gap-5 md:gap-20 min-h-screen bg-slate-900 py-5 md:py-10'>
            <div className='flex flex-col-reverse md:flex-row items-center bg-slate-800 py-2 md:py-10 mx-2 md:mx-10 shadow-black shadow-xl rounded-md px-2 md:px-10'>
                <h2 className='text-4xl text-slate-300 font-SoulGazeItalic'>Score: <span className={`${scoreColor}`}>{quizAttemptResults.score?.toFixed(1)} / 100</span></h2>
                <div className='flex flex-grow justify-center items-center'>
                    <h1 className='text-green-400 text-xl md:text-5xl font-Bowdown tracking-widest'>Results for <span className='text-slate-300 font-SoulGazeItalic'>{quizAttemptResults.quiz.title}</span></h1>
                </div>
            </div>
            {quizAttemptResults.quiz.questions?.map((question, index) => (
                <div className={`flex flex-col flex-grow justify-center bg-slate-800 p-2 md:p-5 mx-2 md:mx-10 shadow-black shadow-xl rounded-md border border-slate-900 font-SoulGaze tracking-widest ${question.selectedAnswer !== question.correctAnswer && 'border-l-red-500 border-l-4'}`}>
                <h1 className="text-slate-300 font-bold text-3xl">{he.decode(question.questionText)}</h1>
                    <div className='flex flex-col md:px-20 pt-3 md:pt-10'>
                        {question.options?.map((option, index) => (
                            handleAnswerDisplay(question.selectedAnswer as string, question.correctAnswer as string, option)
                        ))}
                    </div>
                </div>))}
                <div className='mx-2 md:mx-10'>
                    <button onClick={() => { routePath.split('/').includes('quiz-attempt-results') ? navigate(-1):navigate('/')}} className=' shadow-black shadow-xl border border-slate-600 bg-slate-800 p-4 text-2xl w-full font-bold hover:text-slate-200 rounded-xl hover:border-slate-600'>
                        <p className='text-green-400 font-Bowdown tracking-widest font-thin'>FINISH</p>
                    </button>
                </div>
        </div>
    )
}

export default Results;