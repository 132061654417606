import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWindowClose } from "react-icons/fa";

import { createQuizAttemptRequest } from '../endpoints';
import { Quiz, QuizAttempt } from '../types';
import { useAppDispatch, useAppSelector } from '../store/store';
import Modal from '../Components/Modal';
import { setQuizAttempt } from '../store/features/QuizAttemptSlice';
import QuizListComponent from '../Components/QuizListComponent';
import PageHeaderBanner from '../Components/PageHeaderBanner';

const AttemptQuiz = () => {

    const quizList = useAppSelector((state) => state.quizList.quizList);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ name, setName ] = useState('');
    const [selectedQuiz, setSelectedQuiz] = useState<Quiz>();

    const handleQuizSelection = async () => {
        // e.preventDefault();
        if (!selectedQuiz) {
            return;
        }
        // const quizAttempt = await createQuizAttemptRequest(selectedQuiz.id, name, selectedQuiz);
        // const quiz: QuizAttempt = {
        //     id: quizAttempt.insertedId,
        //     quizId: selectedQuiz.id,
        //     name: name,
        //     quiz: selectedQuiz,
        // }
        // dispatch(setQuizAttempt(quiz));
        navigate('/attempt-quiz/' + selectedQuiz.id);
    }

    useEffect(() => {
        handleQuizSelection();
    }, [selectedQuiz]);

    return (
        <>
            <div className=' flex flex-col gap-10 md:gap-20 min-h-screen bg-slate-900 py-5 md:py-10'>
                <PageHeaderBanner title='Select a Quiz to Test Your Knowledge!' />
                <QuizListComponent quizList={quizList} setSelectedQuiz={setSelectedQuiz} />
            </div>
            {/* <Modal openState={selectedQuiz} title='Create Quiz' onClose={() => {setSelectedQuiz(undefined)}}>
                <div className={'bg-slate-900 text-slate-500 p-5 rounded-lg w-1/3'}>
                    <div className='flex justify-end w-fill'>
                        <button onClick={() => {setSelectedQuiz(undefined)}}>
                            <FaWindowClose className='text-2xl'/>
                        </button>
                    </div>
                    <div className='flex justify-center items-center'>
                        <div className='flex-grow text-center'>
                            <h1 className='pb-5 text-3xl uppercase font-bold text-slate-400'>Please Enter Your Name</h1>
                        </div>
                    </div>
                    <form className='flex flex-col' onSubmit={(e) => {handleQuizSelection(e)}}>
                        <input type='text' className='p-2 text-slate-100 text-xl bg-slate-500 rounded-md' placeholder={'Name'.toUpperCase()} value={name} onChange={(e) => { setName(e.target.value) }} />
                        <button type='submit' className='mt-10 py-2 text-xl uppercase font-semibold text-slate-300 border border-slate-400 rounded-md'>Start Attempt</button>
                    </form>
                </div>
            </Modal> */}
        </>
    );
};

export default AttemptQuiz;