import React, { useRef, useEffect } from 'react';

interface TextFieldProps {
    placeholder: string;
    inputValue: string;
    setInputValue: (value: string) => void;
    styles?: string;
}

export default (props: TextFieldProps) => {
    const { placeholder, inputValue, setInputValue, styles } = props;
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [inputValue]);

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    return (
        <textarea
            ref={textareaRef}
            className={`p-2 text-slate-100 text-xl bg-slate-900 rounded-md border border-slate-600 ${inputValue === '' ? 'font-SoulGazeItalic' : 'font-SoulGaze'} ${styles}`}
            placeholder={placeholder.toUpperCase()}
            value={inputValue}
            onChange={handleInput}
            rows={1} // Start with a single row
            style={{ overflow: 'hidden', resize: 'none' }} // Prevent manual resizing
        />
    );
}