// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAPtAcIzO6hS-5GL5bC8M0Wdwsd5eoVys8",
  authDomain: "quiz-me-95455.firebaseapp.com",
  projectId: "quiz-me-95455",
  storageBucket: "quiz-me-95455.appspot.com",
  messagingSenderId: "527131709514",
  appId: "1:527131709514:web:220d17315096a748cfd6b3",
  measurementId: "G-WV2TCX4QFZ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);