import { Question, Quiz, UserProfile } from './types';

export const getQuizRequest = async (id: string) => {
    const response = await fetch(
        `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quiz?id=${id}`,
        {
            method: 'GET',
        }
    );
    const data = await response.json();
    console.log(data);
    return data;
}

export const createQuizServerRequest: (title: string, author: UserProfile, userIdToken:string, questions: Question[]) => Promise<{insertedId:string, acknowledged:boolean}> = async (title: string, author: UserProfile, userIdToken:string, questions: Question[]) => {
    
   try{
    let body = author === undefined ? JSON.stringify({ title }) : JSON.stringify({ title, author, questions });
    console.log('AUTHOR AUTHOR AUTHOR', author);
    console.log('BODY BODY BODY', userIdToken);
    const response = await fetch(
        'https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quiz',
        {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userIdToken,
            },
        }
    );
    const data = await response.json();
    console.log(data);
    return { insertedId: data.insertedId, acknowledged: data.acknowledged };
   }catch(error){
       console.error('Error creating quiz:', error);
       throw error;
   }
}

export const updateQuizQuestionsServerRequest = async (id: string, updatedQuestions: Question[], userIdToken:string) => {
    const response = await fetch(
        `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quiz/update-questions?id=${id}`,
        {
            method: 'PUT',
            body: JSON.stringify({updatedQuestions}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userIdToken,
            },
        }
    );
    const data = await response.json();
    console.log(data);
    return { modifiedCount: data.modifiedCount, acknowledged: data.acknowledged };
}

export const createQuestionServerRequest = async (id: string, question: Question, userIdToken:string) => {
    const response = await fetch(
        `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quiz?id=${id}`,
        {
            method: 'POST',
            body: JSON.stringify(question),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userIdToken,
            },
            // headers: {
            //     'Content-Type': 'application/json',
            // },
        }
    );
    const data = await response.json();
    console.log(data);
    return { insertedId: data.insertedId, acknowledged: data.acknowledged };
}

export const fetchQuizListRequest = async (userId: string, userIdToken: string) => {
    const response = await fetch(
        `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quizzes?userId=${userId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userIdToken,
            },
        }
    );
    const data = await response.json();
    console.log(data);
    const quizList = data.map((quiz: any) => {
        return {
            id: quiz._id,
            title: quiz.title,
            questions: quiz.questions,
            author: quiz.author,
        };
    });
    console.log('From', quizList);
    return quizList;
}

export const fetchQuizExcludeAnswersRequest = async (id: string) => {
    const response = await fetch(
        `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quizzes/attempt?id=${id}`,
        {
            method: 'GET',
        }
    );
    const quizResponse = await response.json();
    const { _id, ...data} = quizResponse;
    // console.log(data);
    // const quizList = data.map((quiz: any) => {
    //     return {
    //         id: quiz._id,
    //         title: quiz.title,
    //         questions: quiz.questions,
    //     };
    // });
    console.log('From', {id: _id, ...data});
    return {id: _id, ...data};
}

export const fetchQuizListExcludeAnswersRequest = async () => {
    const response = await fetch(
        'https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quizzes/attempt',
        {
            method: 'GET',
        }
    );
    const data = await response.json();
    const quizList = data.map((quiz: any) => {
        return {
            id: quiz._id,
            title: quiz.title,
            questions: quiz.questions,
        };
    });
    return quizList;
}

export const createQuizAttemptRequest = async (quizId: string, attemptDate: Date, name: string, quiz: Quiz) => {
    const response = await fetch(
        `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quizzes/attempt`,
        {
            method: 'POST',
            body: JSON.stringify({ quizId, attemptDate, name, quiz }),
            // headers: {
            //     'Content-Type': 'application/json',
            // },
        }
    );
    const data = await response.json();
    console.log(data);
    return { insertedId: data.insertedId, acknowledged: data.acknowledged };
}

export const submitQuizAttemptRequest = async (quiz: {id: string, quiz: Quiz, score: number}) => {
    console.log(quiz);
    const response = await fetch(
        'https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/finishAttempt',
        {
            method: 'POST',
            body: JSON.stringify(quiz),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    const data = await response.json();
    console.log(data);
    return { insertedId: data.insertedId, acknowledged: data.acknowledged };
}

// export const deleteQuizByIdRequest = async (id: string) => {
//     console.log(JSON.stringify(id));
//     const response = await fetch(
//         `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quiz/delete?id=${id}`,
//         {
//             method: 'POST',
//             body: JSON.stringify( id ),
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         }
//     );
//     const data = await response.json();
//     console.log(data);
//     return { deletedCount: data.deletedCount, acknowledged: data.acknowledged };
// }

export const deleteQuizByIdRequest = async (id: string) => {
    console.log(JSON.stringify(id));
    try {
        const response = await fetch(
            `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quiz/delete?id=${id}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = await response.json();
        console.log(data);
        return { quizResult: data.quizResult, attemptsResult: data.attemptsResult };
    } catch (error) {
        console.error('Error deleting quiz:', error);
        throw error;
    }
}

export const getQuizAttemptsByIdRequest = async (id: string) => {
    const response = await fetch(
        `https://ad0eqe2ap6.execute-api.us-east-1.amazonaws.com/default/quiz/attempts?id=${id}`,
        {
            method: 'GET',
        }
    );
    const data = await response.json();
    console.log(data);
    return data;
}
