import {useState} from 'react';

export default ({...props}) => {

    const [wiggleEffect, setWiggleEffect] = useState(false);
    const [pressedEffect, setPressedEffect] = useState(false);

    return (
        <div onClick={() => {setPressedEffect(true)}} className={`${
            wiggleEffect && "animate-wiggle"
          } tansition ease-in shadow-lg rounded-lg border border-slate-600 shadow-black hover:bg-slate-900`}>
            <button className={`
            ${
            wiggleEffect && "animate-wiggle"
            }
            ${
            pressedEffect && "animate-press"
            }
           bg-blue-500 p-4 h-full text-white rounded hover:bg-blue-700 hover:shadow-xl`}
          onMouseEnter={() => setWiggleEffect(true)}
          onMouseLeave={() => setWiggleEffect(false)}
          onAnimationEnd={() => setWiggleEffect(false)} {...props}>
                {props.children}
            </button>
        </div>
    );
}