import React from 'react';
import { LiaChevronDownSolid } from "react-icons/lia";

interface QM3DropDownProps {
    label: string;
    options: string[];
    selectedOption: string;
    setSelectedOption: (option: string) => void;
    }

export default ({...props}: QM3DropDownProps) => {

    const { label, options, selectedOption, setSelectedOption } = props;
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(e.target.value);
    };
    
  return (
    <div className='flex items-center w-11/12 md:w-1/3 h-10'>
      <select onChange={(e) => handleChange(e)} className={`flex-grow h-full w-11/12 text-center text-xl appearance-none shadow-black shadow-md bg-slate-900 text-green-400 custom-select rounded-lg ${selectedOption ? 'font-SoulGaze' : 'font-SoulGazeItalic'}`}>
      <option value={selectedOption} disabled selected>{label}</option>
        {options.map((option, index) => (
            <option key={index} value={option} className='w-11/12 md:w-1/3'>{option}</option>
        ))}
      <LiaChevronDownSolid className='bg-slate-900 text-slate-200 font-bold h-full'/>
      </select>
    </div>
  );
};