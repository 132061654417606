import React, { useEffect, useState } from 'react';
import { FaWindowClose } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';

import { getQuizRequest } from '../endpoints';
import { Question, Quiz, QuizAttempt, UserProfile, } from '../types';
import { useAppDispatch, useAppSelector } from '../store/store';
import { createQuizAttempt, fetchQuizForAttempt, submitQuizAttemptResult, updateQuestion } from '../store/features/QuizAttemptSlice';

import Modal from '../Components/Modal';
import LoadingOverlay from '../Components/LoadingOverlay';
import { firestore } from '../Firebase/utils';
import FloatingCard from '../Components/FloatingCard';
import he from 'he';

// interface question {
//     questionText: string;
//     options: string[];
//     selectedAnswer?: string;
// }

const AttemptQuiz = () => {

    // const quizAttempt: QuizAttemptInit = useAppSelector((state) => state.quizAttempt.quizAttemptInit);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user: UserProfile = useAppSelector((state) => state.user.user);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const quizAttemptInit = useAppSelector((state) => state.quizAttempt.quiz);
    const quizAttempt = useAppSelector((state) => state.quizAttempt.quizAttempt);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isQuizInitModalOpen, setIsQuizInitModalOpen] = useState(true);
    const [name, setName] = useState(user.displayName);
    const [ modalMessage, setModalMessage ] = useState('');
    const [ localLoading, setLocalLoading ] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        
        const fetchQuiz = async () => {
            if (id) {
                const quiz = (await dispatch(fetchQuizForAttempt(id))).payload as Quiz;
            }
        };
        fetchQuiz();
    }, [quizAttempt]);


    const startAttempt = async (event: React.FormEvent<HTMLFormElement>) => {
        let date = new Date();
        event?.preventDefault();
        if(name === '' && id){
            return;
        }
        if(id){
            await dispatch(createQuizAttempt({quizId: id, attemptDate: date, name, quiz: quizAttemptInit} as QuizAttempt));
            setIsQuizInitModalOpen(false);
        }
    }

    const optionSelectionHandler = (option: string) => {
        let selectedAnswer;
        if(option === quizAttempt.quiz.questions[currentQuestionIndex].selectedAnswer){
            selectedAnswer = undefined;
        }else{
            selectedAnswer = option;
        }
        dispatch(updateQuestion({
            ...quizAttempt.quiz.questions[currentQuestionIndex],
            selectedAnswer
        }));
    }

    const handleNext = () => {
        if(currentQuestionIndex === quizAttempt.quiz.questions.length - 1){
            return;
        }else{
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    }

    const handleBack = () => {
        if(currentQuestionIndex === 0){
            return;
        }else{
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    }

    const handleFinish = () => {
        if(quizAttempt.quiz.questions.map(question => question.selectedAnswer).includes(undefined)){
            setModalMessage('Please answer all questions before finishing');
            setIsModalOpen(true);
            return;
        }else{
            setModalMessage('Quiz Completed');
            setIsModalOpen(true);
        }
    }

    const handleSubmit = async () => {
        setLocalLoading(true);
        let score = 0;
        let date = new Date();
        const originalQuiz: Quiz = await getQuizRequest(quizAttempt.quiz.id);
        quizAttempt.quiz.questions.forEach((question) => {
            if(question.selectedAnswer === originalQuiz.questions.find(q => q.id === question.id)?.correctAnswer){
                score++;
            }
        });

        score = (score / quizAttempt.quiz.questions.length) * 100;
        const finalAttempt = {id: quizAttempt.id, quiz:{...quizAttempt.quiz, questions: quizAttempt.quiz.questions.map(question => {
            return {
                ...question,
                correctAnswer: originalQuiz.questions.find(q => q.id === question.id)?.correctAnswer
            }
        }
    )}};
        await dispatch(submitQuizAttemptResult({...finalAttempt, score, attemptDate: date}));
        const quizAttemptRef = firestore.collection('quizAttempts');
        if(finalAttempt.quiz?.author?.email){
            quizAttemptRef.add({
                to: [finalAttempt.quiz.author.email],
                from:'contact@viperdigitaltech.com',
                replyTo: 'contact@viperdigitaltech.com',
                message: {
                    subject: `Quiz Attempt Submitted for ${finalAttempt.quiz.title}`,
                    text: `${name} has submitted an attempt for your quiz: ${finalAttempt.quiz.title}. They scored ${score}%`,
                    html: `${name} has submitted an attempt for your quiz: ${finalAttempt.quiz.title}. They scored ${score}%`
                }
            });
        }
        // to: ['someone@example.com'],
// message: {
//   subject: 'Hello from Firebase!',
//   text: 'This is the plaintext section of the email body.',
//   html: 'This is the <code>HTML</code> section of the email body.',
// }
        
        // await submitQuizAttemptRequest({...finalAttempt, score});
        setLocalLoading(false);
        navigate('/results');
    }

    if(!quizAttempt.quiz.questions || name === ''){
        return (
            <div className=' flex flex-col gap-20 min-h-screen bg-slate-900 py-10'>
                <Modal openState={isQuizInitModalOpen} title='Please Enter Your Name' onClose={() => {setIsQuizInitModalOpen(false); navigate(-1)}}>
                    <form className='flex flex-col' onSubmit={(e) => {startAttempt(e)}}>
                        <input type='text' className={`p-2 text-slate-100 text-xl bg-slate-500 rounded-md ${name === '' ? 'font-SoulGazeItalic' : 'font-SoulGaze'}`} placeholder={'Name'.toUpperCase()} value={name} onChange={(e) => { setName(e.target.value) }} />
                        <button type='submit' className='mt-10 py-2 text-xl uppercase font-semibold text-slate-300 border border-slate-400 rounded-md font-SoulGaze'>Start Attempt</button>
                    </form>
                </Modal>
            </div>)
    }

    return (
        <div className=' flex flex-col gap-10 md:gap-20 min-h-screen bg-slate-900 py-5 md:py-10 tracking-widest'>
            <FloatingCard className='items-center py-10 mx-2 md:mx-10 hover:cursor-default bg-slate-800 '>
                <h1 className="text-slate-300 text-3xl md:text-5xl font-Bowdown">{he.decode(quizAttempt.quiz.title)}</h1>
            </FloatingCard>
            <FloatingCard className='flex flex-col flex-grow relative justify-center bg-slate-800 px-5 py-5 mx-2 md:mx-10 shadow-black shadow-xl rounded-md'>
                <div className='absolute top-2'> 
                    <p className='text-green-500 text-3xl md:text-4xl font-bold font-Bowdown'>{currentQuestionIndex + 1} / {quizAttempt.quiz.questions.length}</p>
                </div>
                <h1 className="text-slate-300 mt-10 md:mt-14 font-bold text-lg md:text-3xl font-SoulGaze">{he.decode(quizAttempt.quiz.questions[currentQuestionIndex]?.questionText)}</h1>
                <div className='flex flex-col md:px-20 pt-5 md:pt-10'>
                    {quizAttempt.quiz.questions[currentQuestionIndex]?.options?.map((option, index) => (
                        <button onClick={() => optionSelectionHandler(option)} className={`text-slate-300 p-5 rounded-md shadow-md shadow-black my-2 text-lg font-bold border font-SoulGaze ${option === quizAttempt.quiz.questions[currentQuestionIndex].selectedAnswer ? 'border-green-500' : 'border-slate-900'} ${option === quizAttempt.quiz.questions[currentQuestionIndex].selectedAnswer ? 'hover:border-green-500' : 'border-slate-900'} ${option !== quizAttempt.quiz.questions[currentQuestionIndex].selectedAnswer ? 'bg-slate-900' : 'bg-slate-950'} hover:border-slate-500 hover:bg-slate-950`}>{he.decode(option)}</button>
                    ))}
                </div>
                <div className='flex gap-5 md:gap-10 md:px-20 pt-10 font-SoulGaze'>
                    <button onClick={() => {handleBack()}} className='flex-grow bg-slate-900 text-slate-300 p-5 rounded-md shadow-md shadow-black text-xl font-bold border border-slate-900 hover:border-slate-500 hover:bg-slate-950 hover:text-slate-00'>Back</button>
                    {currentQuestionIndex === quizAttempt.quiz.questions.length -1 ? 
                        <button onClick={() => {handleFinish()}} className='flex-grow bg-slate-900 text-slate-300 p-5 rounded-md shadow-md shadow-black text-xl font-bold border border-slate-900 hover:border-slate-500 hover:bg-slate-950 hover:text-slate-00'>Finish</button>
                        :
                        <button onClick={() => {handleNext()}} className='flex-grow bg-slate-900 text-slate-300 p-5 rounded-md shadow-md shadow-black text-xl font-bold border border-slate-900 hover:border-slate-500 hover:bg-slate-950 hover:text-slate-00'>Next</button>
                    }
                </div> 
            </FloatingCard>
            <Modal openState={isQuizInitModalOpen} title='Please Enter Your Name' onClose={() => {setIsQuizInitModalOpen(false); navigate(-1)}}>
                <form className='flex flex-col' onSubmit={(e) => {startAttempt(e)}}>
                    <input type='text' className={`p-2 text-slate-100 text-xl bg-slate-500 rounded-md ${name === '' ? 'font-SoulGazeItalic' : 'font-SoulGaze'}`} placeholder={'Name'.toUpperCase()} value={name} onChange={(e) => { setName(e.target.value) }} />
                    <button type='submit' className='mt-10 py-2 text-xl uppercase font-semibold text-slate-300 border border-slate-400 rounded-md font-SoulGaze'>Start Attempt</button>
                </form>
            </Modal>
            <Modal openState={isModalOpen} onClose={() => {setIsModalOpen(false)}}>
                <div className='flex justify-center items-center'>
                    <div className='flex-grow text-center'>
                        <h1 className='pb-5 text-3xl uppercase text-green-400 font-Bowdown tracking-widest'>{modalMessage || 'Quiz Finished'}</h1>
                    </div>
                </div>
                {modalMessage === 'Quiz Completed' ? 
                    <button onClick={() => {handleSubmit()}} className='mt-10 py-2 text-xl uppercase font-semibold text-slate-300 border border-slate-400 rounded-md font-SoulGaze w-full'>Submit Quiz</button>
                    : 
                    <button onClick={() => {setIsModalOpen(false)}} className='mt-10 py-2 text-xl uppercase font-semibold text-slate-300 border border-slate-400 rounded-md font-SoulGaze w-full'>Close</button>
                }
            </Modal>
            {localLoading && <LoadingOverlay />}
        </div>
    );
};

export default AttemptQuiz;