import react from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


export default (props: any) => {

    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();

    const handleClick = () => {
        if(props?.hamburgerToggle){
            props.hamburgerToggle();
        }
        if(props.user.uid === '' && !props.allow){
            props.setIsSignInModalOpen(true);
            return;
        }
        navigate(props.path);
        
    }

    return (
        <button onClick={() => {handleClick()}} {...props} className={`${pathname.includes(props.path) ? 'text-green-400 border-green-400' : 'text-slate-300'}  md:w-fit p-2 rounded-lg font-bold font-SoulGaze tracking-widest ${props.className}`}>
            {props.label}
        </button>
    );
}