import React, { useEffect, useState } from 'react';
import axios from 'axios';

import QM3DropDown from '../Components/QM3DropDown';
import { useAppSelector, useAppDispatch } from '../store/store'
import { Question, UserProfile } from '../types';
import { categories } from '../constants'
import { useNavigate } from 'react-router-dom';
import { createQuizRequest } from '../store/CreateQuizSlice';
import PageHeaderBanner from '../Components/PageHeaderBanner';
import TextField from '../Components/TextField';
import he from 'he';
import withLoading from '../HigherOrderComponents/withLoading';
import StyledButton from '../Components/StyledButton';


interface CreateQuizPayload {
  acknowledged: boolean;
  id: string;
  insertedId: string;
  questions: [];
  title: string;
}

interface QuizComponentProps {
  setLoading: (loading: boolean) => void;
}

const QuizComponent: React.FC<QuizComponentProps> = ({setLoading}) => {
  const [ numberOfQuestions, setNumberOfQuestions ] = useState<string>('10');
  const [ selectedCategory, setSelectedCategory ] = useState<string>('');
  const [ quizTitle, setQuizTitle ] = useState<string>('');
  const [ difficulty, setDifficulty ] = useState<string>('easy');
  const [questions, setQuestions] = useState<Question[]>([]);
  const user = useAppSelector(state => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(quizTitle === '') {
      alert('Please enter a quiz title');
      return;
    }
    if(selectedCategory === '') {
      alert('Please select a category');
      return;
    }

    fetchQuestions();

  }

  const randomizeOptions = (options: string[]) => {
    let currentIndex = options.length;
    while (currentIndex !== 0) {
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [options[currentIndex], options[randomIndex]] = [options[randomIndex], options[currentIndex]];
    };
    return options;
  }

  const fetchQuestions = async () => {
    setLoading(true);
      try {
        const response = await axios.get(`https://opentdb.com/api.php?amount=${numberOfQuestions === '0' ? '5' : numberOfQuestions}&category=${categories.indexOf(selectedCategory) + 9}&difficulty=${difficulty.toLowerCase()}&type=multiple`);
        
        const tempQuestions: Question[] = [];
        response.data.results.map( (question: any, index: number) => {
          tempQuestions.push({
            id: index.toString(),
            questionText: he.decode(question.question),
            options: randomizeOptions(question.incorrect_answers.concat(question.correct_answer)).map((option: string) => he.decode(option)),
            correctAnswer: he.decode(question.correct_answer),
          });
        });
        setQuestions(tempQuestions);
      } catch (error) {
        console.error('Error fetching quiz questions:', error);
        setLoading(false);
      }
    };

  useEffect(() => {
    if(questions.length > 0 && quizTitle !== ''){
      handleCreateQuiz();
      setLoading(false);
    }
  }, [questions]);

  const handleCreateQuiz = async () => {
    const newQuiz = await dispatch(createQuizRequest({ title: quizTitle, questions: questions, author: user as UserProfile, userIdToken: user.userIdToken }));
    setQuizTitle('');
    const quizPayload = newQuiz.payload as CreateQuizPayload;
    navigate(`/new-quiz/${quizPayload.id}`);
};

  console.log('questions', questions);


  return (
    <div className=' flex flex-col gap-10 md:gap-20 min-h-screen w-full bg-slate-900 py-5 md:py-10'>
      <PageHeaderBanner title={'Create Quiz: ' + quizTitle} />
      <div className='flex flex-col flex-grow justify-center bg-slate-800 md:px-5 md:py-5 mx-2 md:mx-10 shadow-black shadow-xl rounded-md'>
        <form className='flex flex-col gap-4 items-center w-full' onSubmit={(e) => { handleSubmit(e) }}>
          <TextField placeholder='Enter Quiz Title' inputValue={quizTitle} setInputValue={setQuizTitle} styles='w-11/12 md:w-1/3 shadow-black shadow-md'/>
          <TextField placeholder='Number of Questions' inputValue={numberOfQuestions} setInputValue={setNumberOfQuestions} styles='w-11/12 md:w-1/3 shadow-black shadow-md'/>
          <QM3DropDown label='Select a Category' options={categories} selectedOption={selectedCategory} setSelectedOption={setSelectedCategory}/>
          <QM3DropDown label='Set Difficulty Level' options={['EASY', 'MEDIUM', 'HARD']} selectedOption={difficulty} setSelectedOption={setDifficulty}/>
          <StyledButton label={'Create Quiz!'} type='submit' className='mt-10 py-2 text-xl w-1/3 uppercase font-semibold rounded-md'/>
        </form>
      </div>
    </div>
  );
};

export default withLoading((props: any) => <QuizComponent {...props}/>);

const quiz = [
  {
      "type": "multiple",
      "difficulty": "hard",
      "category": "Entertainment: Musicals &amp; Theatres",
      "question": "After England, more Shakespeare plays are set in this present day country than in any other.",
      "correct_answer": "Italy",
      "incorrect_answers": [
          "United States",
          "Greece",
          "France"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "easy",
      "category": "Entertainment: Video Games",
      "question": "Which of the following is not a character in the video game Doki Doki Literature Club?",
      "correct_answer": "Nico",
      "incorrect_answers": [
          "Monika",
          "Natsuki",
          "Sayori"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "easy",
      "category": "Science: Computers",
      "question": "What is the most preferred image format used for logos in the Wikimedia database?",
      "correct_answer": ".svg",
      "incorrect_answers": [
          ".png",
          ".jpeg",
          ".gif"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "hard",
      "category": "Art",
      "question": "Albrecht D&uuml;rer&#039;s birthplace and place of death were in...",
      "correct_answer": "N&uuml;rnberg",
      "incorrect_answers": [
          "Augsburg",
          "Bamberg",
          "Berlin"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "medium",
      "category": "Entertainment: Video Games",
      "question": "How many unique items does &quot;Borderlands 2&quot; claim to have?",
      "correct_answer": "87 Bazillion ",
      "incorrect_answers": [
          "87 Million",
          "87 Trillion",
          "87 Gazillion "
      ]
  },
  {
      "type": "multiple",
      "difficulty": "hard",
      "category": "General Knowledge",
      "question": "Which of the following languages does NOT use gender as a part of its grammar?",
      "correct_answer": "Turkish",
      "incorrect_answers": [
          "German",
          "Danish",
          "Polish"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "easy",
      "category": "General Knowledge",
      "question": "Which American-owned brewery led the country in sales by volume in 2015?",
      "correct_answer": "D. G. Yuengling and Son, Inc",
      "incorrect_answers": [
          "Anheuser Busch",
          "Boston Beer Company",
          "Miller Coors"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "hard",
      "category": "Geography",
      "question": "What is the name of rocky region that spans most of eastern Canada?",
      "correct_answer": "Canadian Shield",
      "incorrect_answers": [
          "Rocky Mountains",
          "Appalachian Mountains",
          "Himalayas"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "easy",
      "category": "Entertainment: Film",
      "question": "Who plays Alice in the Resident Evil movies?",
      "correct_answer": "Milla Jovovich",
      "incorrect_answers": [
          "Madison Derpe",
          "Milla Johnson",
          "Kim Demp"
      ]
  },
  {
      "type": "multiple",
      "difficulty": "hard",
      "category": "Entertainment: Video Games",
      "question": "What military rank is the protagonist &quot;Nomad&quot; in &quot;Crysis&quot;?",
      "correct_answer": "First Lieutenant",
      "incorrect_answers": [
          "Captain",
          "First Sargent ",
          "Staff Sargent"
      ]
  }
]