import React, { useState, useCallback } from 'react';
import LoadingOverlay from '../Components/LoadingOverlay';

interface WithLoadingProps {
    setLoading: (loading: boolean) => void;
}

const withLoading = <P extends object>(Component: React.ComponentType<P>) => {
    const WrappedComponent: React.FC<P> = (props) => {
        const [loading, setLoading] = useState(false);

        const handleSetLoading = useCallback((loading: boolean) => {
            setLoading(loading);
        }, []);


        return (
            <>
                {loading && <LoadingOverlay />}
                <Component {...(props as P)} setLoading={handleSetLoading} loading={loading}/>
            </>
        );
    };

    WrappedComponent.displayName = `WithLoading(${Component.displayName || Component.name || 'Component'})`;

    return React.memo(WrappedComponent);
};

export default withLoading;