import React from 'react';
import { FaCheckSquare } from 'react-icons/fa';

interface RadioProps {
    id: string;
    label: string;
    value: string;
    checked?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default (props: RadioProps) => {
    return (
        <div className='flex gap-2 my-1'>
            <input className='peer appearance-none' type='radio' id={props.value} value={props.value} checked={props.checked} onChange={props.onChange} />
            <FaCheckSquare className='peer-checked:text-green-400 text-3xl'/>
            <label className='text-lg md:text-2xl peer-checked:text-green-400 font-SoulGaze tracking-widest' htmlFor={props.value}>{props.label}</label>
        </div>
    );
}