import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { UserSlice } from './features/UserSlice';
import { QuizSlice } from './CreateQuizSlice';
import { QuizListSlice } from './features/QuizListSlice';
import { QuizAttemptSlice } from './features/QuizAttemptSlice';
import { QuizAttemptResultsSlice } from './features/QuizAttemptResultsSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const rootReducer = combineReducers({
    user: UserSlice.reducer,
    quiz: QuizSlice.reducer,
    quizList: QuizListSlice.reducer,
    quizAttempt: QuizAttemptSlice.reducer,
    quizAttemptResults: QuizAttemptResultsSlice.reducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: rootReducer,
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;

export const persistor = persistStore(store);
export default store;