import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit, FaWindowClose } from "react-icons/fa";
import { BsEyeFill } from "react-icons/bs";

import { getQuizAttemptsByIdRequest } from '../endpoints';
import { Quiz } from '../types';
import { useAppDispatch, useAppSelector } from '../store/store';
import { createQuiz } from '../store/CreateQuizSlice';
import { fetchQuizList as fetchQuizListAction } from '../store/features/QuizListSlice';
import { setQuizAttemptResults } from '../store/features/QuizAttemptResultsSlice';
import QuizListComponent from '../Components/QuizListComponent';
import Modal from '../Components/Modal';
import withLoading from '../HigherOrderComponents/withLoading';
import PageHeaderBanner from '../Components/PageHeaderBanner';

interface ManageQuizProps {
    setLoading: (loading: boolean) => void;
}

const ManageQuiz: React.FC<ManageQuizProps> = ({setLoading}) => {

    const user = useAppSelector((state) => state.user.user);
    const quizList = useAppSelector((state) => state.quizList.quizList);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedQuiz, setSelectedQuiz] = React.useState<Quiz>();

    const handleEditSelection = () => {
        if(selectedQuiz){
            dispatch(createQuiz(selectedQuiz));
            navigate(`/new-quiz/${selectedQuiz.id}/edit`);
        }
    }

    const handleViewAttemptsSelection = async () => {
        if(selectedQuiz){
            // const quizAttempts = await getQuizAttemptsByIdRequest(selectedQuiz.id);
            // dispatch(setQuizAttemptResults(quizAttempts));
            navigate(`/quiz-attempt-results/${selectedQuiz.id}`);
        }
    }

    const fetchQuizList = async () => {
        setLoading(true);
        await dispatch(fetchQuizListAction({userId: user.uid, userIdToken: user.userIdToken}));
        setLoading(false);
    };

    useEffect(() => {
        if(user){
            fetchQuizList();
        }
    }, [user]);

    console.log('quizList', quizList);


    return (
        <div className=' flex flex-col gap-10 md:gap-20 min-h-screen bg-slate-900 py-5 md:py-10'>
            <PageHeaderBanner title='Manage Your Quizzes Here!' />
                <div>
                    <QuizListComponent quizList={quizList} setSelectedQuiz={setSelectedQuiz} deleteButton={true}/>
                </div>
                <Modal openState={selectedQuiz} title={`Modify ${selectedQuiz?.title}`} onClose={() => {setSelectedQuiz(undefined)}}>
                    <div className='flex gap-3 md:gap-10'>
                        <button onClick={handleEditSelection} className='flex w-1/2 text-slate-300 p-2 md:p-5 rounded-md shadow-md shadow-black my-2 md:text-lg font-bold border border-slate-700 bg-slate-900 hover:border-slate-500 hover:bg-slate-950'>
                            <FaRegEdit className='text-2xl'/>
                            <p className='flex-grow text-green-400'>Edit</p>
                        </button>
                        <button onClick={handleViewAttemptsSelection} className='flex w-1/2 text-slate-300 p-2 md:p-5 rounded-md shadow-md shadow-black my-2 md:text-lg font-bold border border-slate-700 bg-slate-900 hover:border-slate-500 hover:bg-slate-950'>
                            <BsEyeFill  className='text-2xl'/>
                            <p className='flex-grow text-green-400'>View Attempts</p>
                        </button>
                </div>
                </Modal>
        </div>);
};

export default withLoading((props: any) => <ManageQuiz {...props}/>);

