import { firebaseConfig } from "./config"
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import { User } from '../types';

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const GoogleAuthProvider: firebase.auth.GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
GoogleAuthProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = async  (): Promise<User> => {
    const user = await auth.signInWithPopup(GoogleAuthProvider);
    const userIdToken = await user.user?.getIdToken();
    if(user?.user?.displayName && user?.user?.email && user?.user?.uid){
        return {uid: user?.user?.uid, displayName: user?.user?.displayName, email: user?.user?.email, userIdToken: userIdToken} as User;
    }else{
        return {} as User;
    }
}

firebase.auth().onAuthStateChanged(async (user) => {
    if(user
        && user.email
        && user.displayName
        && user.uid){
    }
}
)
    
export const signOut = () => auth.signOut();