import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Quiz, QuizAttempt, Question } from '../../types';

interface QuizAttemptResultsSliceState {
    quizAttempt: QuizAttempt[];
}

const initialState: QuizAttemptResultsSliceState = {
    quizAttempt: [{
        id:'',
        quizId: '',
        attemptDate: new Date(),
        name: '',
        quiz: {} as Quiz,
    }],
}

export const QuizAttemptResultsSlice = createSlice({
    name: 'quizAttemptResult',
    initialState,
    reducers: {
        setQuizAttemptResults: (state, action: PayloadAction<QuizAttempt[]>) => {
            state.quizAttempt = [...action.payload];
        }
    }
});

export default QuizAttemptResultsSlice.reducer;

export const { setQuizAttemptResults } = QuizAttemptResultsSlice.actions;