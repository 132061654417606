
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './App.css';
import './index.css';

import Home from './Pages/Home';
import CreateQuiz from './Pages/CreateQuiz';
import AttemptQuizList from './Pages/AttemptQuizList';
import ManageQuiz from './Pages/ManageQuiz';
import AttemptQuiz from './Pages/AttemptQuiz';
import Results from './Pages/Results';
import QuizAttemptResultsList from './Pages/QuizAttemptResultsList';
import { useEffect } from 'react';
import NavBar from './Components/NavBar';
import useAuth from './CutomHooks/useAuth';
import QuizTemp from './Pages/QuizTemp';
import Footer from './Components/Footer';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default function App() {

  useAuth();

  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quiz-temp" element={<QuizTemp setLoading={function (loading: boolean): void {
          throw new Error('Function not implemented.');
        } } />} />
        <Route path="/new-quiz/:id?" element={<CreateQuiz setLoading={function (loading: boolean): void {
          throw new Error('Function not implemented.');
        } } />} />
        <Route path="/new-quiz/:id?/edit" element={<CreateQuiz setLoading={function (loading: boolean): void {
          throw new Error('Function not implemented.');
        } } />} />
        <Route path="/attempt-quiz" element={<AttemptQuizList />} >
          {/* <Route path=":id" element={<AttemptQuiz />} /> */}
        </Route>
        <Route path="/attempt-quiz/:id" element={<AttemptQuiz />} />
        <Route path="/manage-quiz" element={<ManageQuiz />} />
        <Route path="/results" element={<Results />} />
        <Route path="/quiz-attempt-results" element={<QuizAttemptResultsList loading setLoading={(loading: boolean): void => {throw new Error('Function not implemented')}}/>} />
        <Route path="/quiz-attempt-results/:id?" element={<QuizAttemptResultsList loading setLoading={(loading: boolean): void => {throw new Error('Function not implemented')}}/>} />
        <Route path="/quiz-attempt-results/:id/results" element={<Results />} />
        {/* <Route path="/manage-quiz" element={<Results />} /> */}
      </Routes>
      <Footer />
    </Router>
  )
}

