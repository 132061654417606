import { useEffect } from 'react';
import { auth } from '../Firebase/utils'; // Adjust the import path as necessary

import { useAppDispatch } from '../store/store';
import { setUser } from '../store/features/UserSlice';

const useAuth = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user && user.email && user.displayName && user.uid) {
                const userIdToken = await user.getIdToken();
                // Dispatch user information to the store
                if(user && userIdToken){
                    dispatch(setUser({ uid: user.uid, email:user.email, displayName:user.displayName, userIdToken: userIdToken }));
                }
                // dispatch(setUser({ ...user, userIdToken: userIdToken }));
            }
        });

        return () => unsubscribe();
    }, []);
};

export default useAuth;