import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';


export default (props: any) => {

    const navigate = useNavigate();

    return (
        <button onClick={() => navigate(-1)} className={`flex items-center text-3xl font-bold text-slate-200 ${props.additionalStyles}`}>
            <IoArrowBackOutline className='text-green-400 text-3xl md:text-5xl'/>
            <h1 className='text-lg md:text-3xl'>Back</h1>
        </button>
    )
}