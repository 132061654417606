import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaGhost } from "react-icons/fa";

import { QuizAttempt } from '../types';
import { setQuizAttemptResult } from '../store/features/QuizAttemptSlice';
import { useAppSelector, useAppDispatch } from '../store/store';
import { getQuizAttemptsByIdRequest } from '../endpoints';
import { setQuizAttemptResults } from '../store/features/QuizAttemptResultsSlice';
import PageHeaderBanner from '../Components/PageHeaderBanner';
import withLoading from '../HigherOrderComponents/withLoading';

interface QuizAttemptResultsListProps {
    setLoading: (loading: boolean) => void;
    loading: boolean;
}

 const QuizAttemptResultsList: React.FC<QuizAttemptResultsListProps> = ({loading, setLoading}) => {

    const quizAttemptList = useAppSelector((state) => state.quizAttemptResults.quizAttempt);
    const quizId = useLocation().pathname.split('/')[2];
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleQuizAttemptSelection = (quizAttempt: QuizAttempt) => {
        dispatch(setQuizAttemptResult({...quizAttempt, score: calculateScore(quizAttempt)}));
        navigate('results');
    }

    const calculateScore = (quizAttempt: QuizAttempt) => {
        let score = 0;
        quizAttempt.quiz.questions.forEach((question) => {
            if(question.selectedAnswer === question.correctAnswer){
                score++;
            }
        });
        return score / quizAttempt.quiz.questions.length * 100;
    }

    const handleViewAttemptsSelection = async () => {
        setLoading(true);
        const quizAttempts = await getQuizAttemptsByIdRequest(quizId);
        dispatch(setQuizAttemptResults(quizAttempts));
        setLoading(false);
    }

    useEffect(() => {
        if(quizId){
            handleViewAttemptsSelection();
        }

        return () => {
            dispatch(setQuizAttemptResults([]));
        }

    }, [quizId]);

    console.log(quizId);

    if(quizAttemptList.length === 0){
        return (
            <div className='flex flex-col gap-10 md:gap-20 min-h-screen bg-slate-900 py-5 md:py-10'>
                <PageHeaderBanner title='Quiz Attempt Result' />
                <div className='flex relative flex-grow justify-center items-center bg-slate-800 py-10 mx-2 md:mx-10 shadow-black shadow-xl rounded-md'>
                    <FaGhost style={{color:''}} className='absolute w-full h-full py-10'/>
                    <h1 className='text-green-400 font-bold text-2xl md:text-5xl z-10'>No Attempts Found for this Quiz</h1>
                </div>
            </div>
        );
    }
    
    return (
        <div className=' flex flex-col gap-10 md:gap-20 min-h-screen bg-slate-900 py-5 md:py-10'>
            <PageHeaderBanner title={`Quiz Attempt Results for`} span={`  ${quizAttemptList[0].quiz.title}`} />
            {/* <div className='flex justify-center bg-slate-800 py-10 mx-2 md:mx-10 shadow-black shadow-xl rounded-md'>
                {quizAttemptList[0]?.quiz.title ? 
                <h1 className="text-green-400 font-bold text-2xl md:text-5xl">Quiz Attempt Results for <span className='border-slate-400 border-b-4'>{quizAttemptList[0].quiz.title}</span></h1>
                :
                <h1 className="text-green-400 font-bold text-2xl md:text-5xl">Quiz Attempt Results</h1>}
            </div> */}
        <div className='flex flex-col flex-grow justify-center bg-slate-800 md:px-5 py-5 mx-2 md:mx-10 shadow-black shadow-xl rounded-md'>
        <div className='flex flex-col px-2 md:px-20 md:pt-10'>
        {!loading && quizAttemptList.map((quizAttempt) => (
          <button
            onClick={() => handleQuizAttemptSelection(quizAttempt)}
            key={quizAttempt.id}
            className={`text-green-400 p-2 md:p-5 rounded-md shadow-md shadow-black my-2 md:text-lg font-bold border border-slate-400 bg-slate-900 hover:border-slate-500 hover:bg-slate-950`}
          >
            <h1 className='text-3xl font-SoulGaze tracking-widest'>{quizAttempt.name}</h1>
          </button>
        ))}
      </div>
    </div>
        </div>
    );
};

export default withLoading((props: any) => <QuizAttemptResultsList  {...props}/>);