export default () => {
    return (
        <div className='flex justify-center items-center h-12 bg-green-600 text-slate-700 text-xs font-SoulGaze tracking-wide'>
            <div className='flex justify-evenly md:justify-center md:gap-80 items-center w-full  md:px-4'>
                <p className='text-center'>©2024 - All rights reserved</p>
                <div  className="h-9 w-0.5 border border-slate-50 bg-slate-50 md:border-slate-700 md:bg-slate-700"/>
                <p className='flex  flex-col text-center'>Developed by Viper Digital <a href='https://www.viperdigitaltech.com' target="_blank" className='text-slate-700 hover:text-slate-300 underline font-SoulGazeItalic'>www.viperdigitaltech.com</a></p>
            </div>
        </div>
    );

}