import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Quiz, QuizAttempt, QuizAttemptInit, QuizAttemptResult, Question, } from '../../types';
import { createQuizAttemptRequest, fetchQuizExcludeAnswersRequest, submitQuizAttemptRequest } from '../../endpoints';

interface QuizAttemptState {
    quiz: Quiz;
    quizAttempt: QuizAttempt;
    quizAttemptResult: QuizAttemptResult;
}


const initialState: QuizAttemptState = {
    quiz: {
        id: '',
        title: '',
        questions: []
    },
    quizAttempt: {
        id:'',
        quizId: '',
        attemptDate: new Date(),
        name: '',
        quiz: {} as Quiz,
    },
    quizAttemptResult: {
        id:'',
        attemptDate: new Date(),
        quiz: {} as Quiz,
        score: 0,
    }
}

export const fetchQuizForAttempt = createAsyncThunk(
    'quizAttempt/fetchQuizForAttempt',
    async (quizId: string) => {
        const quiz: Quiz = await fetchQuizExcludeAnswersRequest(quizId);
        return quiz;
    }
);

export const createQuizAttempt = createAsyncThunk(
    'quizAttempt/createQuizAttempt',
    async (quizAttemptInit: QuizAttemptInit) => {
        const response = await createQuizAttemptRequest(quizAttemptInit.quizId, quizAttemptInit.attemptDate, quizAttemptInit.name, quizAttemptInit.quiz);
        return {
            id: response.insertedId,
            quizId: quizAttemptInit.quizId,
            attemptDate: quizAttemptInit.attemptDate,
            name: quizAttemptInit.name,
            quiz: quizAttemptInit.quiz,
        }
    }
);

export const submitQuizAttemptResult = createAsyncThunk(
    'quizAttempt/updateQuizAttemptResult',
    async (quizAttempt: QuizAttemptResult) => {
        await submitQuizAttemptRequest(quizAttempt);
        return quizAttempt;
    }
);
    


export const QuizAttemptSlice = createSlice({
    name: 'quizzes',
    initialState,
    reducers: {
        setQuizAttempt: (state, action: PayloadAction<QuizAttempt>) => {
            state.quizAttempt = action.payload;
        },
        setQuizAttemptResult: (state, action: PayloadAction<QuizAttemptResult>) => {
            state.quizAttemptResult = action.payload;
        },
        updateQuestion: (state, action: PayloadAction<Question>) => {
            const index = state.quizAttempt.quiz.questions.findIndex(question => question.id === action.payload.id);
            state.quizAttempt.quiz.questions[index] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitQuizAttemptResult.fulfilled, (state, action: PayloadAction<QuizAttemptResult>) => {
            state.quizAttemptResult = action.payload;
        });
        builder.addCase(fetchQuizForAttempt.fulfilled, (state, action: PayloadAction<Quiz>) => {
            state.quiz = action.payload;
        });
        builder.addCase(createQuizAttempt.fulfilled, (state, action: PayloadAction<QuizAttempt>) => {
            state.quizAttempt = action.payload;
        });
    },
});

export default QuizAttemptSlice.reducer;

export const { setQuizAttempt, setQuizAttemptResult, updateQuestion } = QuizAttemptSlice.actions;