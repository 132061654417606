import React, { useEffect } from 'react';
import { FaWindowClose } from 'react-icons/fa';

const Modal = ({ ...props }) => {
    const { title, children, onClose, openState, left } = props;

    useEffect(() => {
        if (openState) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup function to reset overflow when the component is unmounted
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [openState]);

    return (
        <div style={{ display: openState ? 'flex' : 'none' }} className={`fixed top-0 right-0 ${left || 'left-0'} bottom-0 md:-left-10 py-9 w-auto h-full bg-black bg-opacity-50 items-center justify-center z-50`}>
            <div className='modal-custom-scrollbar flex flex-col bg-slate-900 text-slate-500 p-5 rounded-lg w-11/12 md:w-1/3 border border-slate-500 max-h-dvh md:max-h-screen overflow-y-auto'>
                <button onClick={() => { onClose() }} className='self-end pb-8'>
                    <FaWindowClose className='text-2xl md:text-4xl text-green-400' />
                </button>
                <div className='flex justify-center items-center'>
                    <div className='flex-grow text-center'>
                        <h1 className='pb-5 text-2xl md:text-3xl uppercase text-green-400 font-Bowdown tracking-widest'>{title}</h1>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;