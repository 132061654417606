import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/store';
import { User, UserProfile } from '../types';
import { FaWindowClose, FaGoogle } from 'react-icons/fa';
import { signInWithGoogle, signOut } from '../Firebase/utils';
import { setUser } from '../store/features/UserSlice';
import Modal from './Modal';
import { useLocation, useNavigate } from 'react-router-dom';

import logo from '../assets/images/logo-nobg.png';
import NavButtons from './NavButtons';

const NavBar = () => {

    const user: UserProfile = useAppSelector((state) => state.user.user);
    const navigate = useNavigate();
    const location = useLocation();
    const routePath = location.pathname;
    const dispatch = useAppDispatch();
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const buttonText = user.uid ? 'Sign Out' : 'Sign In';

    const handleAuth = async () => {
        if(user.uid === ''){
            setIsOpen(false);
            setIsSignInModalOpen(true);
        }else{
            await signOut();
            dispatch(setUser({uid: '', email: '', displayName: '', userIdToken: ''}));
            navigate('/');
            setIsOpen(false);
        }
    }

    const hamburgerToggle = () => {
        setIsOpen(!isOpen);
    }

    const handleGoogleSignIn = async () => {
        const {...user} = await signInWithGoogle() as User;
        if (user) {
            dispatch(setUser(user));
            setIsSignInModalOpen(false);
            setIsOpen(false);
        }
    };

    return (
        <div className='flex w-full justify-between p-5 bg-slate-900 shadow-md shadow-black relative h-20'>
            <div onClick={() => navigate('/')} className='flex items-center md:w-1/3 gap-2 hover:cursor-pointer'>
                <img src={logo} className='h-full bg-slate-300 rounded-full'/>
                <h1 className='text-3xl text-slate-300 font-Bowdown'>QU<span className='text-green-400'>i</span>Z M<span className='text-green-400'>3</span></h1>
            </div>
            <div className='flex md:w-full space-x-5'>
                <div className='hidden md:flex-grow md:flex gap-5 justify-end'>
                    <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} path={'/'} label={'Home'} className={`${location.pathname !== '/' && 'text-slate-300'}`}/>
                    <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} path={'/new-quiz'} label={'Create Quiz'}/>
                    <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} path={'/manage-quiz'} label={'Manage Quiz'}/>
                    <NavButtons user={user} allow={true} path={'/attempt-quiz'} label={'Attempt Quiz'}/>
                    <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} path={'/quiz-temp'} label={'Quiz Temp'}/>
                </div>
                <button onClick={handleAuth} className='hidden md:flex text-white border border-slate-600 px-3 py-2 rounded-md shadow-md shadow-black hover:bg-slate-700 hover:text-green-400 font-SoulGaze tracking-widest'>
                    {buttonText}
                </button>
                <button onClick={hamburgerToggle} className='flex md:hidden flex-col justify-center items-center w-10 h-10 bg-slate-800 rounded-md shadow-md hover:bg-slate-700'>
                    <span className={`block w-6 h-0.5 bg-white mb-1 transition-transform duration-300 ${isOpen ? 'rotate-45 translate-y-2 bg-green-400' : ''}`}></span>
                    <span className={`block w-6 h-0.5 bg-white mb-1 transition-opacity duration-300 ${isOpen ? 'opacity-0' : ''}`}></span>
                    <span className={`block w-6 h-0.5 bg-white transition-transform duration-300 ${isOpen ? '-rotate-45 -translate-y-1 bg-green-400' : ''}`}></span>
                </button>
                <section>
                    <div className={`flex flex-col absolute top-20 -right-2/3 h-screen w-2/3 z-50 bg-slate-900 shadow-md shadow-black p-5 transition-transform duration-300 ${isOpen ? '-translate-x-full' : 'hidden'}`}>
                        <div>
                            <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} hamburgerToggle={hamburgerToggle} path={'/'} label={'Home'} className={`flex w-full justify-center ${location.pathname !== '/' && 'text-slate-300 border-slate-300'} border border-slate-600 mb-6 shadow-black shadow-md`}/>
                            <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} hamburgerToggle={hamburgerToggle} path={'/new-quiz'} label={'Create Quiz'} className={`flex w-full justify-center border border-slate-600 mb-6 shadow-black shadow-md`}/>
                            <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} hamburgerToggle={hamburgerToggle} path={'/manage-quiz'} label={'Manage Quiz'} className={`flex w-full justify-center border border-slate-600 mb-6 shadow-black shadow-md`}/>
                            <NavButtons user={user} allow={true} hamburgerToggle={hamburgerToggle} path={'/attempt-quiz'} label={'Attempt Quiz'} className={`flex w-full justify-center border border-slate-600 mb-6 shadow-black shadow-md`}/>
                            <NavButtons user={user} setIsSignInModalOpen={setIsSignInModalOpen} hamburgerToggle={hamburgerToggle} path={'/quiz-temp'} label={'Quiz Temp'} className={`flex w-full justify-center border border-slate-600 mb-6 shadow-black shadow-md`}/>
                        </div>
                        <button onClick={handleAuth} className='text-white bg-slate-800 px-3 py-2 rounded-md shadow-md hover:bg-slate-700 hover:text-green-400 font-SoulGaze tracking-widest'>
                            {buttonText}
                        </button>
                    </div>
                </section>
                <Modal title='Please Sign In' onClose={() => { setIsSignInModalOpen(false) }} openState={isSignInModalOpen} left={'-left-5'}>
                    {/* <div className={'bg-slate-900 text-slate-500 p-5 rounded-lg w-11/12 md:w-1/3 border'}> */}
                        {/* <div className='flex justify-end w-fill mb-10 md:mb-0'>
                            <button onClick={() => { setIsSignInModalOpen(false) }}>
                                <FaWindowClose className='text-2xl text-slate-300' />
                            </button>
                        </div> */}
                        {/* <div className='flex justify-center items-center'>
                            <div className='flex-grow text-center'>
                                <h1 className='pb-5 text-3xl uppercase text-green-400 font-Bowdown tracking-widest'>Please Sign In</h1>
                            </div>
                        </div> */}
                        <button onClick={() => handleGoogleSignIn()} className='flex flex-row gap-10 w-full items-center justify-center mt-10 py-2 text-xl uppercase font-SoulGaze tracking-wider text-slate-300 border border-slate-400 rounded-md'>
                            <p className='flex gap-4 text-green-400'><FaGoogle />Signin With Google</p>
                        </button>
                    {/* </div> */}
                </Modal>
            </div>
        </div>
    );
    };
    export default NavBar;
