import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGoogle, FaWindowClose } from "react-icons/fa";

import { useAppDispatch, useAppSelector } from '../store/store';
import { fetchQuizListExcludeAnswers, fetchQuizList as fetchQuizListAction } from '../store/features/QuizListSlice';
import HomeButton from '../Components/HomeButton';
import Modal from '../Components/Modal';
import withLoading from '../HigherOrderComponents/withLoading';
import { signInWithGoogle } from '../Firebase/utils';
import { setUser } from '../store/features/UserSlice';
import { User } from '../types';
import logo from '../assets/images/logo-nobg.png';
import LogoToken from '../Components/LogoToken';

interface HomePageProps {
    setLoading: (loading: boolean) => void;
}

const Home: React.FC<HomePageProps> = ({setLoading}) => {
    const user = useAppSelector((state) => state.user.user);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

    const fetchQuizExcludeAnswersList = async () => {
        setLoading(true);
        await dispatch(fetchQuizListExcludeAnswers());
        setLoading(false);
        navigate('/attempt-quiz');
    };

    const fetchQuizList = async () => {
        if(user.userIdToken === ''){
            setIsSignInModalOpen(true);
        }else{
            // setLoading(true);
            // await dispatch(fetchQuizListAction({userId: user.uid, userIdToken: user.userIdToken}));
            // setLoading(false);
            // setIsSignInModalOpen(false);
            navigate('/manage-quiz');
        }
    };

    const handleGoogleSignIn = async () => {
        const {...user} = await signInWithGoogle() as User;
        if (user) {
            dispatch(setUser(user));
            setIsSignInModalOpen(false);
        }
    };
    
    const handleCreateQuiz = async () => {
        if(user.userIdToken === ''){
            setIsSignInModalOpen(true);
        }else{
            navigate('/new-quiz');
        }
    }

    const handleQuizTemp = async () => {
        if(user.userIdToken === ''){
            setIsSignInModalOpen(true);
        }else{
            navigate('/quiz-temp');
        }
    }



    return (
        <div className='flex flex-col items-center min-h-screen w-full bg-slate-900 pb-10'>
            {/* <button onClick={() => {handleAuth()}} className='text-slate-100'>{user.uid ? 'Sign Out' : 'Sign In'}</button> */}
            <div className='flex flex-col md:flex-row gap-8 items-center mt-20 mb-36 md:mb-20 h-44 '>
                <LogoToken />
                {/* <img src={logo} className='h-full aspect-square bg-slate-300 rounded-full'/> */}
                <h1 className='text-5xl md:text-9xl text-slate-300 font-bold font-Bowdown tracking-widest'>QU<span className='text-green-400'>i</span>Z M<span className='text-green-400'>3</span></h1>
            </div>
            <div className='flex flex-grow flex-col justify-center gap-5 w-full md:w-1/2 px-5'>
                <HomeButton label="Create Quiz" onClick={() => handleCreateQuiz()} />
                <HomeButton label="Attempt Quiz" onClick={() => { fetchQuizExcludeAnswersList() }} />
                <HomeButton label="Manage Quiz" onClick={() => { fetchQuizList() }} />
                <HomeButton label="Quiz Temp" onClick={() => { handleQuizTemp() }} />
            </div>
            <Modal title='Please Sign In' onClose={() => { setIsSignInModalOpen(false) }} openState={isSignInModalOpen}>
                {/* <div className={'bg-slate-900 text-slate-500 p-5 rounded-lg w-11/12 md:w-1/3 border'}> */}
                    {/* <div className='flex justify-end w-fill mb-10 md:mb-0'>
                        <button onClick={() => { setIsSignInModalOpen(false) }}>
                            <FaWindowClose className='text-2xl text-slate-300' />
                        </button>
                    </div> */}
                    {/* <div className='flex justify-center items-center'>
                        <div className='flex-grow text-center'>
                            <h1 className='pb-5 text-3xl uppercase font-Bowdown text-green-400 tracking-widest'>Please Sign In</h1>
                        </div>
                    </div> */}
                    <button onClick={() => handleGoogleSignIn()} className='flex flex-row gap-10 w-full items-center justify-center mt-10 py-2 text-xl uppercase font-semibold text-slate-300 border border-slate-400 rounded-md'>
                        {/*  */}
                        <p className='flex gap-4 font-SoulGaze text-green-400'><FaGoogle /> Signin With Google</p>
                    </button>
                {/* </div> */}
            </Modal>
        </div>
    );
};

export default withLoading((props: any) => <Home {...props} />);