import React, { useEffect, useState } from 'react';
import { RiAddBoxFill } from "react-icons/ri";
import { BsClipboard2CheckFill, BsClipboard2Fill } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../store/store';
import {  addQuestionRequest, createQuizRequest, updateQuestionsRequest } from '../store/CreateQuizSlice';
import { Question, Quiz, User, UserProfile } from '../types';
import Modal from '../Components/Modal';
import { FaArrowRight, FaEdit, FaWindowClose } from 'react-icons/fa';
import withLoading from '../HigherOrderComponents/withLoading';
import FloatingCard from '../Components/FloatingCard';
import TextField from '../Components/TextField';
import Radio from '../Components/Radio';

interface QuestionProps {
    question: Question;
    setQuestion: (question: Question) => void;
}

interface CreateQuestionFormProps {
    questionProps: QuestionProps;
    handleInsertChoice: (e: React.FormEvent<HTMLFormElement>) => void;
    handleRemoveChoice: (index: number) => void;
    setCurrentChoice: (choice: string) => void;
    setStep: (step: number) => void;
    currentChoice: string;
}

interface SelectAnswerProps {
    questionProps: QuestionProps;
    setStep: (step: number) => void;
    step: number;
}

interface FinalReviewProps {
    questionProps: QuestionProps;
    setStep: (step: number) => void;
    handleSubmitQuestion: () => void;
    step: number;
}

interface CreateQuizPayload {
    acknowledged: boolean;
    id: string;
    insertedId: string;
    questions: [];
    title: string;
}

interface CreateQuizProps {
    setLoading: (loading: boolean) => void;
}

const questionInit = {
    id: '',
    questionText: '',
    options: [],
    correctAnswer: ''
}

const CreateQuiz: React.FC<CreateQuizProps> = ({setLoading}) => {

    const quiz: Quiz = useAppSelector(state => state.quiz.quiz);
    const questions: Question[] = useAppSelector(state => state.quiz.quiz.questions || []);
    const user: User = useAppSelector(state => state.user.user);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const routhPath = useLocation().pathname;
    const { id:quizId } = useParams();
    const [quizTitle, setQuizTitle] = useState('');
    const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
    const [ isInitModalOpen, setIsInitModalOpen ] = useState<boolean>(true);
    const [ question, setQuestion ] = useState<Question>(questionInit);
    const [ currentChoice, setCurrentChoice ] = useState<string>('');
    const [ step, setStep ] = useState<number>(1);
    const [ editQuestion, setEditQuestion ] = useState<boolean>(false);
    const [ copied, setCopied ] = useState<boolean>(false);

    const displayStep = () => {
        switch(step) {
            case 1:
                return <CreateQuestionForm
                questionProps={{ question, setQuestion }}
                handleRemoveChoice={handleRemoveChoice} 
                handleInsertChoice={handleInsertChoice} 
                setCurrentChoice={setCurrentChoice}
                setStep={setStep}
                currentChoice={currentChoice}/>;
            case 2:
                return <SelectAnswer questionProps={{ question, setQuestion }} setStep={setStep} step={step}/>;
            case 3:
                return <FinalReview questionProps={{ question, setQuestion }} setStep={setStep} step={step} handleSubmitQuestion={handleSubmitQuestion}/>;
            default:
        }
    }

    useEffect(() => {
        if(user.userIdToken === ''){
            navigate('/');
        }
    }, []);
    
    const handleCreateQuiz = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        if (quizTitle === '') {
            alert('Please enter a quiz title');
            return;
        }
        
        setLoading(true);
        const newQuiz = await dispatch(createQuizRequest({ title: quizTitle, author: user as UserProfile, userIdToken: user.userIdToken }));
        setLoading(false);
        setIsModalOpen(false);
        setQuizTitle('');
        const quizPayload = newQuiz.payload as CreateQuizPayload;
        navigate(`/new-quiz/${quizPayload.id}`);
    };

    const handleCreateQuestion = async () => {
        const { id, questionText, options, correctAnswer } = question;
        if(!questionText || !correctAnswer) {
            alert('Please fill out the question and answers');
            setIsModalOpen(false);
            setStep(1);
            return;
        }
        const idString = quiz.questions?.length.toString() || "0";
        await dispatch(addQuestionRequest({id:quiz.id, question:{...question, id: idString}, userIdToken: user.userIdToken}));
        
        setStep(1);
        setQuestion({id: '', questionText: '', options: [], correctAnswer: ''});
        setIsModalOpen(false);
    }

    const handleUpdateQuestions = async () => {
        const updatedQuestions = updateItemAtIndex(questions, Number(question.id), question);
        await dispatch(updateQuestionsRequest({id: quiz.id, questions: updatedQuestions, userIdToken: user.userIdToken}));
    }

    const handleSubmitQuestion = async () => {
        setLoading(true);
        if(editQuestion){
            await handleUpdateQuestions();
            setEditQuestion(false);
        }else{
            await handleCreateQuestion();
        }
        setLoading(false);
        setIsModalOpen(false);
    }


    const handleInsertChoice = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(currentChoice.length < 1) return
        setQuestion({...question, options:[...question.options, currentChoice]});
        setCurrentChoice('');
    }

    const handleRemoveChoice = (index: number) => {
        setQuestion({...question, options: question.options.filter((choice, i) => i !== index)});
    }

    const handleCopyLink = () => {
        if(!quiz.questions?.length){
            alert('Please add questions to your quiz before sharing');
            return;
        }
        const link = `https://quizm3.com/attempt-quiz/${quiz.id}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(link).then(() => {
                setCopied(true);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        } else {
            // Fallback for browsers that do not support navigator.clipboard
            const textArea = document.createElement("textarea");
            textArea.value = link;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                setCopied(true);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    }

    function updateItemAtIndex<T>(array: T[], index: number, payload: T): T[] {
        if (index < 0 || index >= array.length) {
            throw new Error("Index out of bounds");
        }
        return array.map((item, i) => (i === index ? payload : item));
    }

    const handleEditQuestionSelection = (question: Question) => {
        setEditQuestion(true);
        setStep(1);
        setQuestion(question);
        setIsModalOpen(true);
    }

    if(quiz.title === '' || quizId === undefined) {
        return(
            <div className='bg-slate-900 min-h-screen'>
                <Modal openState={isInitModalOpen} title='Create Quiz' onClose={() => { navigate('/') }}>
                    {/* <div className={'bg-slate-900 text-slate-500 p-5 rounded-lg w-11/12 md:w-1/3 border border-slate-400'}>
                        <div className='flex justify-end mb-5 md:mb-8'>
                            <button onClick={() => { navigate('/') }}>
                                <FaWindowClose className='text-2xl md:text-4xl text-green-400' />
                            </button>
                        </div> */}
                        {/* <div className='flex justify-center items-center'>
                            <div className='flex-grow text-center'>
                                <h1 className='pb-5 text-3xl uppercase text-green-400 font-Bowdown tracking-widest'>Create Quiz</h1>
                            </div>
                        </div> */}
                        <form className='flex flex-col' onSubmit={(e) => { handleCreateQuiz(e) }}>
                            <input type='text' className={`p-2 text-slate-100 text-xl bg-slate-500 rounded-md ${quizTitle === '' ? 'font-SoulGazeItalic' : 'font-SoulGaze'}`} placeholder={'Please Enter Your Quiz Title'.toUpperCase()} value={quizTitle} onChange={(e) => { setQuizTitle(e.target.value) }} />
                            <button type='submit' className='mt-10 py-2 text-xl uppercase font-semibold text-slate-300 border border-slate-400 rounded-md font-SoulGaze'>Create Quiz</button>
                        </form>
                    {/* </div> */}
                </Modal>
            </div>
            )
    }

    return (
        <div className='flex flex-col flex-grow min-h-screen w-full p-2 md:p-10 gap-10 items-center bg-slate-900 text-slate-500'>
            <FloatingCard className='w-full'>
                <div className='flex flex-col items-center w-full rounded-lg p-2 gap-5'>
                    <div>
                        <h1 className='text-2xl md:text-5xl text-green-400 font-Bowdown tracking-widest'>{quiz.title}</h1>
                    </div>
                    <button onClick={() => {setEditQuestion(false); setIsModalOpen(true)}} className='border md:p-4 text-2xl w-full font-bold hover:text-slate-200 rounded-xl border-slate-200 hover:border-slate-600 font-SoulGaze tracking-widest'>
                        <p className='text-green-400'>Create Question</p>
                    </button>
                </div>
            </FloatingCard>
            <div onClick={handleCopyLink} style={{ cursor:`${quiz.questions?.length ? 'pointer' : 'not-allowed'}` }} className={'w-full md:w-auto'}>
                <div className='border rounded-lg'>
                    {copied ? 
                        <div className='flex items-center p-2'>
                            <BsClipboard2CheckFill className='text-2xl md:text-2xl text-green-400 mx-4'/>
                            <p className='md:text-xl text-green-400 font-bold line-clamp-1'>{`https://quiz-me-95455.web.app/attempt-quiz/${quiz.id}`}</p>
                        </div>
                        :
                        <div className='flex items-center p-2'>
                            <BsClipboard2Fill className='text-2xl md:text-2xl text-slate-400 mx-4'/>
                            <p className='md:text-xl text-slate-400 font-bold line-clamp-1'>{`https://quiz-me-95455.web.app/attempt-quiz/${quiz.id}`}</p>
                        </div>
                    }
                </div>
                <p className='text-center text-slate-300 font-bold tracking-widest'>{copied ? 'COPIED!' : 'Copy Link to Share'}</p>
            </div>
            <div className='flex flex-col w-full gap-5'>
                {questions?.map((question: Question, index: number) => {
                    return (
                        <FloatingCard key={index} className='w-full'>
                            <div onClick={() => { handleEditQuestionSelection(question) }} key={index} className='flex flex-col gap-5'>
                            <div className='flex flex-col-reverse md:flex-row justify-between'>
                                <h3 className='text-3xl text-green-400 break-words w-10/12 font-SoulGaze tracking-widest'>{question.questionText}</h3>
                                <h5 className='flex gap-4 self-end md:self-start font-bold text-lg md:text-2xl'>Tap to Edit<FaEdit className='text-slate-200 text-2xl'/></h5>
                            </div>
                            <ul>
                                {question.options.map((option: string, index: number) => {
                                    return <li className='text-xl text-slate-300 font-bold font-SoulGaze tracking-widest' key={index}><span className='text-green-400'>--</span> {option}</li>
                                })}
                            </ul>
                            <h4 className='text-2xl text-slate-300 font-SoulGazeItalic'><span className='text-green-400'>Answer:</span> {question.correctAnswer}</h4>
                        </div>
                        </FloatingCard>
                    );
                })}
            </div>
            <button onClick={() => { routhPath.split('/').includes('edit') ?  navigate(-1) : navigate('/') }} className='border p-4 text-2xl w-full font-bold hover:text-slate-200 rounded-xl border-slate-200 hover:border-slate-600'>
                <p className='text-green-400'>FINISH</p>
            </button>
            <Modal openState={isModalOpen} title='Create Question' onClose={() => {setIsModalOpen(false); setQuestion(questionInit)}}>
                {/* <div className='bg-slate-900 text-slate-500 p-2 md:p-5 rounded-lg w-11/12 md:w-1/2'>
                    <div className='flex justify-end w-fill'>
                        <button onClick={() => { setIsModalOpen(false) }}>
                            <FaWindowClose className='text-2xl'/>
                        </button>
                    </div> */}
                    {displayStep()}
                {/* </div> */}
            </Modal>
        </div>
    );
};

export default withLoading((props: any) => <CreateQuiz {...props}/>);

const CreateQuestionForm = (props: CreateQuestionFormProps) => {
    const { question, setQuestion } = props.questionProps;
    return( 
        <div className='flex flex-col'>
            <form onSubmit={(e) => {props.handleInsertChoice(e)}} className='flex flex-col gap-3'>
                <TextField placeholder='*Enter Question' inputValue={question.questionText.trimStart()} setInputValue={(value) => setQuestion({...question, questionText: value} as Question)} styles={'w-full required '}/>
                {question.questionText.trim() === '' && <h4 className='-mt-3 text-md text-yellow-400 font-SoulGazeItalic tracking-widest'>*Question Cannot be Blank</h4>}
                {/* <input type='text' className='w-full p-2 text-slate-100 text-xl bg-slate-500 rounded-md' placeholder='Question' value={question.questionText} onChange={(e) => setQuestion({...question, questionText: e.target.value} as Question)}/> */}
                <div className=''>
                    <section>
                        {question.options.map((option: string, index: number) => {
                            return (
                                <div key={index} className='flex justify-between w-full'>
                                    <h6 className='text-lg md:text-2xl font-SoulGaze tracking-widest'>- {option}</h6>
                                    <button onClick={() => { props.handleRemoveChoice(index) }} type='button' className='text-red-600 hover:border border-red-600 rounded-md px-2 font-SoulGazeItalic text-sm tracking-wide'>Remove</button>
                                </div>
                            );
                        })}
                    </section>
                </div>
                <div className='flex'>
                    <TextField  placeholder='Add Answer Choice' inputValue={props.currentChoice} setInputValue={props.setCurrentChoice} styles={'w-full'}/>
                    {/* <input type='text' className='p-2 flex-grow text-slate-100 text-xl bg-slate-500 rounded-md' placeholder='Choice' value={props.currentChoice} onChange={(e) => props.setCurrentChoice(e.target.value)}/> */}
                    <button type='submit'>
                        <RiAddBoxFill className='text-4xl text-green-500'/>
                    </button>
                </div>
                {/* <input type='text' placeholder='Answer' /> */}
                {/* <button type='submit'>Create Question</button> */}
            </form>
            {question.options.length < 2 && <h4 className='mb-5 text-md text-yellow-400 font-SoulGazeItalic tracking-widest'>*At least two(2) answer choices are required</h4>}
            <button className='border mt-8 p-4 text-2xl font-bold text-green-400 hover:text-slate-200 rounded-xl border-slate-700 hover:border-slate-300 shadow-black shadow-md font-SoulGaze' onClick={() => {question.options.length >= 2 && question.questionText.trim() !== '' && props.setStep(2)}}>Next</button>
        </div>
    )
}

const SelectAnswer = (props: SelectAnswerProps) => {

    const { question, setQuestion } = props.questionProps;
    return (
        <div className='flex flex-col'>
    <h1 className='text-3xl text-slate-300 text-center font-SoulGaze my-10'>{question.questionText}</h1>
    <h4 className='mb-5 text-md md:text-2xl font-SoulGazeItalic'>Select the Correct Answer Below...</h4>
    <form className='flex flex-col'>
        {question.options.map((choice: string, index: number) => (
            <div key={index} className=''>
                <Radio id={`choice-${index}`} label={choice} value={question.options[index]} checked={choice === question.correctAnswer} onChange={(e) => { setQuestion({...question, correctAnswer: e.target.value} as Question)}}/>
                {/* <input
                    className='mr-3'
                    type="radio"
                    id={`choice-${index}`}
                    name="answer"
                    value={question.options[index]}
                    onChange={(e) => { setQuestion({...question, correctAnswer: e.target.value} as Question)}}
                />
                <label className='md:text-lg font-SoulGaze tracking-widest' htmlFor={`choice-${index}`}>{choice}</label> */}
            </div>
        ))}
    </form>
    <div className='flex justify-between w-full space-x-5'>
        <button className='flex-grow border mt-8 p-4 text-2xl font-bold text-green-400 hover:text-slate-200 rounded-xl border-slate-700 hover:border-slate-300 shadow-black shadow-md font-SoulGaze' onClick={() => {props.setStep(1)}}>Back</button>
        <button className='flex-grow border mt-8 p-4 text-2xl font-bold text-green-400 hover:text-slate-200 rounded-xl border-slate-700 hover:border-slate-300 shadow-black shadow-md font-SoulGaze' onClick={() => {question.correctAnswer && props.setStep(3)}}>Next</button>
    </div>
</div>
    );
}

const FinalReview = (props: FinalReviewProps) => {

    const { question, setQuestion } = props.questionProps;

    return (
        <div className='flex flex-col justify-center items-center font-SoulGaze'>
            <h1 className='my-5 text-4xl tracking-wider text-slate-200'>Final Review</h1>
            <h2 className='text-2xl my-4'>{question.questionText}</h2>
            <ul className='text-xl my-2'>
            {Array.isArray(question.options) && question.options.map((option: string, index: number) => {
                    return <li key={index} className={`flex items-center gap-2 ${option === question.correctAnswer && 'text-green-400'}`}><FaArrowRight />{option}</li>
                })}
            </ul>
            <h3 className='self-start text-slate-200 font-SoulGazeItalic'><span className='text-green-400 pr-2 font-SoulGaze'>Answer:</span> {question.correctAnswer}</h3>
            <div className='flex justify-between w-full space-x-5'>
                <button className='flex-grow border mt-8 p-4 text-2xl font-bold text-green-400 hover:text-slate-200 rounded-xl border-slate-700 hover:border-slate-300 shadow-black shadow-md font-SoulGaze' onClick={() => props.setStep(2)}>Back</button>
                <button className='flex-grow border mt-8 p-4 text-2xl font-bold text-green-400 hover:text-slate-200 rounded-xl border-slate-700 hover:border-slate-300 shadow-black shadow-md font-SoulGaze' onClick={() => { props.handleSubmitQuestion() }}>Submit</button>
            </div>
        </div>
    );
}