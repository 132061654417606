import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types';

interface UserState {
    user: User;
}

const initialState: UserState = {
    user: {
        uid: '',
        displayName: '',
        email: '',
        userIdToken: '',
    }
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        removeUser: (state) => {
            state.user = {
                uid: '',
                displayName: '',
                email: '',
                userIdToken: '',
            }
        }
    }
});

export default UserSlice.reducer;

export const { setUser, removeUser } = UserSlice.actions;

// {
//     "operationType": "signIn",
//     "credential": {
//         "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUyNmQ5MTdiMWZlOGRlMTMzODJhYTdjYzlhMWQ2ZTkzMjYyZjMzZTIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNTI3MTMxNzA5NTE0LW5sNDNjb29ldWRsM2k5M3JoOXJlZWFhazVyZnFkYzMwLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNTI3MTMxNzA5NTE0LW5sNDNjb29ldWRsM2k5M3JoOXJlZWFhazVyZnFkYzMwLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTExMDIwODk0MTMxMTk1NzI1NDIxIiwiaGQiOiJ2aXBlcmRpZ2l0YWx0ZWNoLmNvbSIsImVtYWlsIjoiY29udGFjdEB2aXBlcmRpZ2l0YWx0ZWNoLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiZUJrNmtwZmJ2VmpuOTlWTnZPYW5ZdyIsImlhdCI6MTcyMjM4MzM0NCwiZXhwIjoxNzIyMzg2OTQ0fQ.aMoQB-jhjX2ZKCLHWwHsM6JjsxF5QxjF6N-L42l9vBxG7jbUpstsJ_S_1zCrGvZR6Y7QZR2VsYzxaKgvf5IFQQtTO3caa-bFt4Kc77DWyLS-PAuqVBtq-u4Ow_IROZnD0aC8_E-YF-V5U8Z_NXBHD8pZnShQWlUxekqQbGnBFeSA755V6RvjILpzHjOGf8_cS_jyjkB6RoVkxZEIuxDAVtlZWZkKDhOE8bxvJHsvpjbEmlS6ZJdNRvJWEFlaAOnMA3KxvJzKRqilc9P6osRWRt1FAZ-laEJ328MgBol2DAg8zdG4jnXZqGZ_yahtHAZqoxb76uvclOKvFzIkY8XwWA",
//         "accessToken": "ya29.a0AXooCguq873XmRXqnA2f9WxBH9i95iPC63UbSjgYr24-kol_TA-Id8j1f9eC1-2fEMR3u139jhkoLMm5tunLLytTrRWuVqoqOxPWXnXeO_TJjDCb4Gygt0x33GsxU-SQfiGLYlmdzJYzbDNJ1A3byckrvtOnW1vxVJkyaCgYKAR4SARMSFQHGX2MiIXZnxGWiKHTaieuUyKut-g0171",
//         "pendingToken": null,
//         "providerId": "google.com",
//         "signInMethod": "google.com"
//     },
//     "additionalUserInfo": {
//         "isNewUser": true,
//         "providerId": "google.com",
//         "profile": {
//             "name": "LePaul Love",
//             "granted_scopes": "https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
//             "id": "111020894131195725421",
//             "verified_email": true,
//             "given_name": "LePaul",
//             "hd": "viperdigitaltech.com",
//             "family_name": "Love",
//             "email": "contact@viperdigitaltech.com",
//             "picture": "https://lh3.googleusercontent.com/a/ACg8ocJcLArQwU9Olspr3mrokgQnG68EEeK8w5HpISYUOh6iqaiYjg=s96-c"
//         }
//     },
//     "user": {
//         "uid": "ZtNgV6m24LU4yjDjnMiYen1ABxH3",
//         "email": "contact@viperdigitaltech.com",
//         "emailVerified": true,
//         "displayName": "LePaul Love",
//         "isAnonymous": false,
//         "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJcLArQwU9Olspr3mrokgQnG68EEeK8w5HpISYUOh6iqaiYjg=s96-c",
//         "providerData": [
//             {
//                 "providerId": "google.com",
//                 "uid": "111020894131195725421",
//                 "displayName": "LePaul Love",
//                 "email": "contact@viperdigitaltech.com",
//                 "phoneNumber": null,
//                 "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJcLArQwU9Olspr3mrokgQnG68EEeK8w5HpISYUOh6iqaiYjg=s96-c"
//             }
//         ],
//         "stsTokenManager": {
//             "refreshToken": "AMf-vBw7O8Z-VseyYZloGIvwI-PJ6gIJnEQNT-O1u5npyiLUf6aEoc5XUQ3KHNoS-zrChPoZ2RnTl_zlXCEfH3Xh-hm9_Qjou9eKuglN0h6Bi_g4rPlbID2RHLPXSRYszhWilSm9L4iyM_ph2qhvszEnqSUMpf65iMlJzWcnJbJemp1J_-AI-pqlUGQ7eORoXKHFgfbx14hRGB0oGLD1O3ZTyCIe36BdHePGky72hL8dSzh4AmJ7wvX0xrtZso_WykwaznA2Q6SLNumbwr6uxdLrdxC0Ow9sXiwXnPa1zpAhaJLQe0jhKIHckXdSeTYpQiY9YQlfY4j7WrNfrNKX2O0mRIwfZh_OkH5RpxncrBdd5g4rM02FdZYUZlqkVDR7n9_RSubnQSDGq65hc4t2ubAUI3-HxWMiHEP987nTemyOPBZMo-UDTW0kjLTmXVY_4ggU9SMK3Cpr",
//             "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjBjYjQyNzQyYWU1OGY0ZGE0NjdiY2RhZWE0Yjk1YTI5ZmJhMGM1ZjkiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTGVQYXVsIExvdmUiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jSmNMQXJRd1U5T2xzcHIzbXJva2dRbkc2OEVFZUs4dzVIcElTWVVPaDZpcWFpWWpnPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3F1aXotbWUtOTU0NTUiLCJhdWQiOiJxdWl6LW1lLTk1NDU1IiwiYXV0aF90aW1lIjoxNzIyMzgzMzQ0LCJ1c2VyX2lkIjoiWnROZ1Y2bTI0TFU0eWpEam5NaVllbjFBQnhIMyIsInN1YiI6Ilp0TmdWNm0yNExVNHlqRGpuTWlZZW4xQUJ4SDMiLCJpYXQiOjE3MjIzODMzNDQsImV4cCI6MTcyMjM4Njk0NCwiZW1haWwiOiJjb250YWN0QHZpcGVyZGlnaXRhbHRlY2guY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMTEwMjA4OTQxMzExOTU3MjU0MjEiXSwiZW1haWwiOlsiY29udGFjdEB2aXBlcmRpZ2l0YWx0ZWNoLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0.p4fxwq5A1lyPDhe-6bYBsleJtWSKr8Yqv05VdEeXb_pDlpZNP-zJynMT3VIC9CRgW_BSyMZUeA9mF114HlMjVF_DHgPlPWgCKvb_eVLAgVJE8gkMmad_gQq7MV5zwh6Q4PIc8BiCIxFY5_uzfuqIygor_69x2OsbS_FNfgDdMEWrtKEnqVuQz8HNDoPwysEVCYgIHGbadsLB7Rke1tvGSqlsdeknKrMuDoSdbvboZEeSgEYaWEjyu85gO8XsoPC_7SMX8yPumgtBffvX97WgHHE3uHKphIKG_C544fHSbI9i6YE3AD4TS-vcPEMCsowzFx3iGlvUX1ZQIRSPaj1qMw",
//             "expirationTime": 1722386945027
//         },
//         "createdAt": "1722383344679",
//         "lastLoginAt": "1722383344680",
//         "apiKey": "AIzaSyAPtAcIzO6hS-5GL5bC8M0Wdwsd5eoVys8",
//         "appName": "[DEFAULT]"
//     }
// }
